<template>
  <div  class="ma-md-5 mb-16 px-0 " >

    <div class="d-flex">
      <v-btn icon small class="px-4 mt-6" to="/profile/menu" v-if="$vuetify.breakpoint.mobile">
        <v-icon size=20>mdi-arrow-left</v-icon>
      </v-btn>

      <div class="text-h6 font-weight-bold my-6">收货地址管理</div>
    </div>
    
    <div class="d-flex justify-space-between">
      <v-btn  dark outlined  tile class="main-btn addbtn" @click="showAddressForm=!showAddressForm">添加新地址</v-btn>
    </div>

    <div>
      <v-container v-if="showAddressForm">
        <v-row>
          <v-col cols="6" md="4" class="px-4">
            <v-text-field
              label="姓"
              required
              v-model="addressItem.lastName"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4" class="px-4">
            <v-text-field
              label="名"
              required
              v-model="addressItem.firstName"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="8" class="px-4">
            <v-select
              :items="country_list"
              v-model="select_country"
              item-text="title"
              item-value="id"
              label="目的"
            ></v-select>
          </v-col>
          <v-col cols="0" md="4" class="px-4">
          
          </v-col>
          <v-col cols="4" md="4" class="px-4">
            <v-select
              :items="province_list"
              v-model="addressItem.select_province"
              item-text="province_name"
              item-value="province_id"
              label="省份/直辖市"
              @change="province_change"
            ></v-select>
          </v-col>
          <v-col cols="4" md="4" class="px-4">
            <v-select
              :items="city_list"
              v-model="addressItem.select_city"
              item-text="city_name"
              item-value="city_id"
              label="城市"
              @change="city_change"
            ></v-select>
          </v-col>
          <v-col cols="4" md="4" class="px-4">
            <v-select
              :items="district_list"
              v-model="addressItem.select_district"
              item-text="district_name"
              item-value="district_id"
              label="地区"
            ></v-select>
          </v-col>
          <v-col cols="12" md="8" class="px-4">
            <v-text-field
              label="详细地址"
              required
              v-model="addressItem.address"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>
          <v-col cols="0" md="4" class="px-4">
          
          </v-col>
          <v-col cols="6" md="4" class="px-4">
            <v-text-field
              label="邮编"
              required
              v-model="addressItem.code"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4" class="px-4">
            <v-text-field
              label="电话"
              required
              v-model="addressItem.tel"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>
          

          <v-col cols="12" md="4"  class="px-4 offset-md-8">
            <v-btn  dark outlined  tile class="main-btn" @click="saveAddress">保存</v-btn>
          </v-col>
          
        </v-row>
      </v-container>
    </div>

    <v-list-item-group
        class="mt-8"
        active-class=""
        color="primary"
      >
        <v-list-item v-for="address in addresses" :key="address.id">
          <template >

            <v-list-item-content>
              
                <v-row>
                  <v-col cols="3">
                    <div>{{address.lastname + address.firstname}}</div>
                    <div>{{address.mobile}}</div>
                  </v-col>
                  <v-col cols="6">
                     {{address.address_info}}
                     <div>{{address.address}}</div>
                  </v-col>
                  
                  <v-col cols="3">
                     <v-btn
                        class="ma-1"
                        plain
                        @click.stop="updateAddress(address.id)"
                      >修改
                      </v-btn>
                  </v-col>
                </v-row>
             
            </v-list-item-content>
          </template>
        </v-list-item>
    </v-list-item-group>



    
   


  </div>
</template>

<script>

import userApi from '@/api/user'
export default {


  data: () => ({
    showAddressForm:false,
    addressItem: {},
    itemid : 0,
    info : {},
    country_list : [{id:1,title:"中国大陆"}],
    select_country :1,
    province_list : [],
    city_list : [],
    district_list : [],
    city_alllist : [],
    district_alllist : [],
    addresses:[]
    

  
    //
  }),
  computed: {


  },
  created() {

    userApi.getarea().then(res => {
      this.province_list = res.data.province
      this.city_alllist = res.data.city
      this.district_alllist = res.data.district

    })
    
    userApi.getaddresses().then(res => {
      this.addresses = res.data.data

    })
    
    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    saveAddress() {

      let params = this.addressItem
      userApi.addaddress(params).then(res => {
        if (res.code==0) {
          this.$message.success("保存收货地址成功！")
          this.addressItem = {}
          this.showAddressForm = false

          userApi.getaddresses().then(res => {
            this.addresses = res.data.data

          })

        } else {
          this.$message.warning("保存收货地址失败！")
        }
      })

    },
    province_change(id) {

      this.city_list = []
      this.city_alllist.forEach(item => {
        if (item.province_id == id){
          this.city_list.push(item)
        }
      })

    },
    city_change(id) {

      this.district_list = []
      this.district_alllist.forEach(item => {
        if (item.city_id == id){
          this.district_list.push(item)
        }
      })

    },
    updateAddress(id) {
      let info = this.addresses.filter(item => item.id==id).pop()

      this.province_change(info.province)
      this.city_change(info.city)

      this.addressItem = {
        lastName : info.lastname,
        firstName : info.firstname,
        select_province : info.province,
        select_city : info.city,
        select_district : info.district,
        address : info.address,
        code : info.zip_code,
        tel : info.mobile,
        id : info.id

      }
      
      this.showAddressForm = true

    }
    
  
  },
};
</script>
<style scoped>

.addbtn {
  
  width:150px;
}
</style>

